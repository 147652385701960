<template>
  <v-app :class="{ blur: showNavigationDrawer }">
    <Teleport to="body">
      <v-navigation-drawer
        v-if="showNavigationDrawer"
        v-model="showNavigationDrawer"
        elevation="24"
        temporary
        class="hidden-md-and-up"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col cols="9">
              <NuxtLink id="navbar-logo" to="/">
                <img
                  src="/images/logo-usersdriven-green.png"
                  alt="Users Driven logo"
                  class="pt-2"
                  loading="lazy"
                  fetchpriority="low"
                  width="160px"
                />
              </NuxtLink>
            </v-col>
            <v-col cols="3" class="ps-5">
              <v-btn
                icon="$close"
                :size="30"
                color="secondary"
                aria-label="Close menu"
                @click="showNavigationDrawer = false"
              />
            </v-col>
          </v-row>
        </v-container>
        <div class="ps-1 mt-3">
          <NavLinks direction="column" show-icons />
        </div>
        <!-- TODO: Introduce dark theme back in the future -->
        <!-- <div class="change-theme-wrapper ps-4">
          <ChangeTheme />
        </div> -->
      </v-navigation-drawer>
    </Teleport>
    <v-app-bar app class="w-100 AppBar" v-bind="appBarProps">
      <v-container>
        <v-row>
          <v-col cols="auto" class="px-0 px-sm-3">
            <v-toolbar-title class="">
              <NuxtLink id="navbar-logo" to="/">
                <img
                  src="/images/logo-usersdriven-white.svg"
                  alt="Users Driven logo"
                  class="pt-2 ps-1 ps-sm-0 ps-md-6 ps-lg-1"
                  loading="eager"
                  fetchpriority="high"
                  width="210px"
                />
              </NuxtLink>
            </v-toolbar-title>
          </v-col>
          <v-col
            class="hidden-sm-and-down"
            offset-lg="2"
            offset-md="1"
            cols="auto"
            align-self="center"
          >
            <v-toolbar-items>
              <NavLinks direction="row" :show-icons="false" color="white" />
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
      <template #append>
        <v-app-bar-nav-icon
          aria-label="Open the menu"
          class="hidden-md-and-up"
          color="white"
          @click.stop="showNavigationDrawer = !showNavigationDrawer"
        />
      </template>
    </v-app-bar>
    <v-main>
      <!-- Vue router goes into action with the NuxtPage component 
          rendering the component according to the components in the pages folder -->
      <NuxtPage />
    </v-main>
    <AppFooter />
    <FeedbackOverlay class="hidden-sm-and-down">
      <template #activator-slot="activatorProps">
        <FloatingBtn :activator-props="activatorProps" />
      </template>
    </FeedbackOverlay>
  </v-app>
</template>

<script setup>
import FeedbackOverlay from '@/components/FeedbackOverlay.vue';
import FloatingBtn from './FloatingBtn.vue';
import NavLinks from './NavLinks.vue';
// import ChangeTheme from './ChangeTheme.vue';
import AppFooter from './AppFooter.vue';

defineOptions({
  name: `DefaultLayout`,
});

const showNavigationDrawer = ref(false);

const runtimeConfig = useRuntimeConfig();

const wakeUpApiServer = async () => {
  try {
    await fetch(`${runtimeConfig.public.API_BASE_URL}/wake-up`);
  } catch (error) {
    console.error(
      `Error occured when trying to perform wake-up http request: `,
      error,
    );
  }
};

const appBarProps = reactive({
  elevation: 0,
  class: `bg-transparent`,
});

const onScroll = () => {
  if (window.scrollY > 10) {
    appBarProps.elevation = 24;
    appBarProps.class = `bg-hero-gradient`;
  } else if (window.scrollY < 10) {
    appBarProps.elevation = 0;
    appBarProps.class = `bg-transparent`;
  }
};

onMounted(() => {
  onScroll();
  if (runtimeConfig.public.NODE_ENV !== 'development') {
    wakeUpApiServer();
  }
  window.addEventListener(`scroll`, onScroll, {
    passive: true,
  });
});

onUnmounted(() => {
  window.removeEventListener(`scroll`, onScroll, {
    passive: true,
  });
});
</script>

<style scoped>
.theme--dark.v-application {
  background: black;
}
#navbar-logo {
  text-decoration: none;
}
.change-theme-wrapper {
  position: absolute;
  bottom: 10px;
}

.v-toolbar.bg-transparent {
  background: transparent !important;
}
.v-toolbar.bg-hero-gradient {
  /* Used when scrolling */
  background: linear-gradient(258.52deg, #2dd4bf 0%, #7f56d9 100%) !important;
}

.blur {
  opacity: 0.3;
}

.v-navigation-drawer__scrim {
  position: fixed;
}
</style>
